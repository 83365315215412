export default [{
  name: 'pengajuan-surat',
  path: '/pengajuan/surat',
  component: () => import('@/views/platform/Pengajuan.vue'),
  admin: false,
  year: 2024,
  notes: '',
  meta: {
    pageTitle: 'Surat Permohonan',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Surat Permohonan',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan',
  path: '/pengajuan',
  component: () => import('@/views/platform/Pengajuan.vue'),
  admin: true,
  year: 2024,
  notes: '',
  meta: {
    pageTitle: 'Daftar Permohonan',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Daftar Permohonan',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-rekomendasi-sementara',
  path: '/pengajuan/rekomendasi-sementara',
  component: () => import('@/views/pengajuan/DaftarRekomendasiSementara.vue'),
  admin: false,
  year: 2024,
  notes: 'perlu_tanggapan',
  meta: {
    pageTitle: 'Daftar Rekomendasi Sementara',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Rekomendasi Sementara',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-konfimasi-rekomendasi-sementara',
  path: '/pengajuan/konfimasi-rekomendasi-sementara',
  component: () => import('@/views/pengajuan/DaftarKonfirmasiRekomendasiSementara.vue'),
  admin: false,
  year: 2023,
  notes: 'perlu_konfirmasi',
  meta: {
    pageTitle: 'Konfirmasi Pembahasan Rekomendasi Sementara',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Rekomendasi Sementara',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-berita-acara',
  path: '/pengajuan/berita-acara',
  component: () => import('@/views/pengajuan/DaftarBeritaAcara.vue'),
  admin: false,
  year: 2024,
  notes: 'berita_acara',
  meta: {
    pageTitle: 'Daftar Berita Acara Konfirmasi Pembahasan Rekomendasi Sementara',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Berita Acara',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-surat-rekomendasi',
  path: '/pengajuan/surat-rekomendasi',
  component: () => import('@/views/pengajuan/DaftarSuratRekomendasi.vue'),
  admin: false,
  year: 2024,
  notes: 'surat_rekomendasi',
  meta: {
    pageTitle: 'Daftar Surat Rekomendasi',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Surat Rekomendasi',
        active: true,
      },
    ],
  },
}]
